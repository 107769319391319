<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="msaGrey"> Labels </v-card-title>
          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="12" :key="reloadLabels">
                <v-simple-table
                  :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
                >
                  <template v-slot:default>
                    <colgroup>
                      <col span="1" />
                      <col span="1" :style="{ width: '80px' }" />
                    </colgroup>
                    <tbody>
                      <tr v-for="label in attachedLabels" :key="label.id">
                        <td class="msaBlue--text">
                          {{ label.name }}
                        </td>

                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-blur
                                text
                                small
                                v-on="on"
                                color="red"
                                @click="detachLabel(label)"
                              >
                                <v-icon> mdi-tag-off-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>Detach Label</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-col align="right">
                  <AttachLabels
                    :labels="notAttachedLabels"
                    @attached="onLabelsAttached($event)"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-row :key="reloadResources">
          <v-col cols="12" v-for="item in resourceTypes" :key="item">
            <LabeledResources :type="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LabeledResources from '@/components/IACompanyLabels/LabeledResources.vue';
import AttachLabels from '@/components/IACompanyLabels/AttachLabels.vue';
export default {
  name: 'CompanyLabels',
  components: {
    LabeledResources,
    AttachLabels,
  },
  computed: {
    attachedLabels() {
      return this.labels.filter((l) => l.isAttached);
    },
    notAttachedLabels() {
      return this.labels.filter((l) => !l.isAttached);
    },
  },
  data() {
    return {
      labels: [],
      resourceTypes: ['document', 'lms', 'formTemplate'],
      reloadLabels: 999,
      reloadResources: 9999,
    };
  },
  methods: {
    getLabels() {
      const params = {
        loaderText: 'loading...',
        companyId: this.$route.params.companyId,
      };
      const url = 'get-company-labels?format=json';
      this.$axios.post(url, params).then((response) => {
        this.labels = response.data.labels;
        this.$store.commit('updateSelectedCompany', {
          id: response.data.company.id,
          name: response.data.company.name,
          status: response.data.company.status,
          companyGroupId: response.data.company.companyGroupId,
        });
      });
    },
    onLabelsAttached(labelIds) {
      this.labels.map((l) => {
        if (labelIds.includes(l.id)) {
          l.isAttached = 1;
        }
      });
      this.reloadLabels++;
      this.reloadResources++;
    },
    detachLabel(label) {
      const params = {
        loaderText: 'loading...',
        companyId: this.$store.getters.selectedCompany.id,
        labelId: label.id,
      };
      const url = 'remove-company-from-label?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          label.isAttached = false;
          this.reloadResources++;
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>

<style></style>
