<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            class="msaBlue white--text"
          >
            <v-icon> mdi-tag-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Attach Labels</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Labels </v-card-title>
      <v-card-text class="mt-2 text-body-1">
        <SearchBar
          @search="onSearch"
          searchLabel="Search by label name..."
          :key="searchKey"
        />
        <v-expansion-panels v-model="expanded">
          <v-expansion-panel
            v-for="(group, index) in filteredLabelGroups"
            :key="index"
            class="my-2 mt-2"
          >
            <v-expansion-panel-header
              hide-actions
              :style="headerStyles(index)"
              :class="headerClass(index)"
              v-slot="{ open }"
            >
              <v-row align="center">
                <v-col class="shrink">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col
                  :class="(open ? 'white--text' : '') + ' font-weight-medium'"
                >
                  {{ group[0].groupName }}
                  <v-badge
                    class="ml-2"
                    :content="group.length"
                    :bordered="open"
                  ></v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                class="labels-list"
                v-model="selected"
                :items="group"
                disable-pagination
                disable-sort
                checkbox-color="msaBlue"
                hide-default-footer
                show-select
                :headers="headers"
              >
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="onCancel()" text class="msaBlue--text font-weight-bold">
          Cancel
        </v-btn>

        <v-btn
          @click="confirm()"
          class="msaBlue white--text"
          :disabled="disabled"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
// import GroupDetail from '../../views/InternalAdmin/CompanyGroup/GroupDetail.vue';
export default {
  name: 'AttachLabels',
  components: {
    SearchBar,
  },
  props: {
    labels: {
      type: Array,
    },
  },
  computed: {
    disabled() {
      return this.selected.length == 0;
    },
    labelIds() {
      return this.selected.map((i) => i.id);
    },
    labelGroups() {
      if (!this.labels) {
        return [];
      }
      const groups = Object.groupBy(this.labels, (label) => label.groupId);

      // Map back into arr [{0: [...labels]}, {1: [...labels]}, etc.]
      return Object.keys(groups).map((key) => groups[key]);
    },
    filteredLabelGroups() {
      if (!this.search) {
        return this.labelGroups;
      }

      return this.labelGroups
        .map((group) => {
          return group.filter(
            (label) =>
              label.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1,
          );
        })
        .filter((group) => group.length > 0); // Without, there will be lines because the v-expansion-panels will still render
    },
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },
  data() {
    return {
      dialog: false,
      selected: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightBg',
        },
      ],
      search: '',
      expanded: undefined,
      searchKey: 9999,
    };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'saving...',
        labels: this.labelIds,
        companyId: this.$store.getters.selectedCompany.id,
      };
      const url = 'add-labels-to-company?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('attached', this.labelIds);
          this.dialog = false;
        })
        .catch((error) => error);
    },
    close() {
      this.selected = [];
      this.dialog = false;
    },
    onSearch(search) {
      this.search = search.trim();
    },
    onCancel() {
      this.search = '';
      this.searchKey++;
      this.dialog = false;
    },
  },
};
</script>
<style>
.labels-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
