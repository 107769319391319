<template>
  <v-card>
    <v-card-title class="msaGrey">
      <v-chip class="mr-2" color="green" text-color="white">{{ count }}</v-chip>
      {{ resourceTitle }}
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="count"
        disable-filtering
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [20, 30, 50],
        }"
        :options.sync="options"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span
            class="msaBlue--text"
            :style="{ cursor: 'pointer' }"
            @click="goToResource(item.name)"
          >
            {{ item.name }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LabeledResources',
  props: {
    type: {
      type: String,
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getResourceItems();
      },
    },
  },
  computed: {
    resourceTitle() {
      switch (this.type) {
        case 'document':
          return 'Resources';
        case 'lms':
          return 'Online Courses';
        case 'formTemplate':
          return 'Form Templates';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Group',
          value: 'groupName',
          class: 'lightGrey',
        },
      ],
      items: [],
      count: 0,
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['name'],
        sortDesc: [false],
      },
    };
  },
  methods: {
    getResourceItems() {
      this.loading = true;
      if (this.options.sortBy.length == 0) {
        this.options.sortBy = ['name'];
      }
      if (this.options.sortDesc.length == 0) {
        this.options.sortDesc = [false];
      }
      const params = {
        companyId: this.$route.params.companyId,
        resourceType: this.type,
        options: this.options,
      };
      const url = 'get-company-labeled-resources?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.count = response.data.count;
          this.loading = false;
        })
        .catch((error) => error);
    },
    goToResource(name) {
      let route = '';
      switch (this.type) {
        case 'document':
          route = 'IAResources';
          break;
        case 'lms':
          route = 'lms';
          break;
        case 'formTemplate':
          route = 'PublicFormTemplates';
          break;
        default:
          break;
      }

      if (route != 'lms') {
        this.$router.push({ name: route, query: { name: name } });
      } else {
        window.open(process.env.VUE_APP_LMS_APP_URL, '_blank');
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
